import React from "react";
import Project from "../../Models/Projects/projects";

export default function ProjectRow({ project }: { project: Project }) {
	return (
		<article>
			<figure>
				<img src={project.image} alt={project.title} />
			</figure>

			<div className="project-info">
				<h3> {project.title} </h3>
				<p> {project.description.trim()} </p>

				<div className="skills">
					{project.skills.map((skill, i) => {
						return <span key={i}> {skill} </span>;
					})}
				</div>
			</div>
		</article>
	);
}
