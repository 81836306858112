import React from "react";
import Home from "./Home/Home";
import Skills from "./Skills/Skills";
import Projects from "./Projects/Projects";
import Footer from "./Footer/Footer";
import Educations from "./Educations/Educations";
import WorkingExperience from "./Experience/Experience";

export default function App() {
	return (
		<>
			<Home />
			<Skills />
			<Projects />
			<section className="timeline-wrapper">
				<WorkingExperience />
				<Educations />
			</section>
			<Footer />
		</>
	);
}
