import React from "react";
import Skill from "../../Models/Skills/skills";

export default function SkillCard({
	skill,
	index,
}: {
	skill: Skill;
	index: number;
}) {
	return (
		<div className="skillCard" data-nr={index}>
			<figure>
				<img src={skill.image} alt={skill.title} />
			</figure>
			<h3 className="percentage"> {skill.percentage}%</h3>
			<h5 className="title"> {skill.title}</h5>
		</div>
	);
}
