import React from "react";

export default function SectionHeader({ title }: { title: string }) {
	return (
		<h2 className="sectionTitle">
			<span />
			{title}
		</h2>
	);
}
