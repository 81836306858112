import React from "react";
import TimelineItem from "../../Models/Shared/timeline";

export default function TimelineRow({ row }: { row: TimelineItem }) {
	return (
		<div className="timelineRow">
			<h4 className="period"> {row.period} </h4>
			<figure className="timeline-event"></figure>
			<div className="timeline-info">
				<h3 className="title">{row.title}</h3>
				<h4 className="subtitle">{row.subtitle}</h4>
				<p className="text">{row.text}</p>
			</div>
		</div>
	);
}
