import React from "react";
import SectionHeader from "../Shared/SectionHeader";
import Project from "../../Models/Projects/projects";
import ProjectRow from "./ProjectRow";

import post from "../../Images/Projects/post.webp";
import app from "../../Images/Projects/app.webp";
import dashboard from "../../Images/Projects/dashboard.webp";
import koelkast from "../../Images/Projects/koelkast.webp";

export default function Projects() {
	const projects: Project[] = [
		{
			title: "MC22L - iOS APP",
			description: `
				Zelfgemaakte iOS app app voor mijn vriendengroep voorzien van verschillende functionaliteiten, waaronder het bedienen van IoT-devices, scores bijhouden tijdens een dartspel en het plaatsen van een bestelling.
				De geplaatste bestelling wordt via Artificial Intelligence (AI) gevalideerd. Vervolgens wordt deze geplaatste bestelling op een TV-scherm (dashboard, zie het volgende project) getoond om de scores van de gebruikers te tonen. Op deze manier later gemakkelijk de kosten per gebruiker te verrekenen.`,
			skills: ["SWIFT", "YOLOV5 AI", "Azure DevOps"],
			image: app,
		},
		{
			title: "MC22L - DASHBOARD",
			description: `
				Website die enkel fungeert als visualisatie van alle (live) data op een TV-scherm. Hierop zijn onder andere de scores van een dartspel te zien, de voorraad van de koelkast (zie het volgende project) en de consumpties per gebruiker, per type, per dag en per maand.
				Dit dashboard communiceert via API en Websockets met de backend om de laatste data te visualiseren.`,
			skills: ["HTML / CSS / JS / RAZOR", "ASP.NET", "Azure DevOps"],
			image: dashboard,
		},
		{
			title: "MC22L - KOELKAST",
			description: `
				Een multifunctionele full-stack applicatie gemonteerd op een koelkast welke draait op een Raspberry Pi. Via een scherm kunnen gebruikers een bestelling plaatsen welke vervolgens worden getoond op het dashboard.
				Aan de hand van deze bestellingen kunnen de consumpties per gebruiker worden bijgehouden, evenals de kosten.
				Daarnaast beschikt deze multifunctionele full-stack applicatie ook over een aantal andere functies, zoals het (automatisch) bijhouden van de voorraad door middel van Artificial Intelligence (AI); aan de binnenkant van de koelkast zit een camera gemonteerd welke na iedere bestelling de voorraad (per biermerk) 'telt' aan de hand van een zelf getraind AI-model. Verder beschikt de applicatie over een sensor om te alarmeren wanneer de koelkastdeur wordt geopend zonder het plaatsen van een bestelling.`,
			skills: ["ELECTRON", "REACT", "YOLOV5 AI", "Azure DevOps"],
			image: koelkast,
		},
		{
			title: "POST - Support dashboard",
			description: `Voor een opdrachtgever heb ik een webapplicatie ontwikkeld die intern wordt gebruikt voor het beheren van IT-apparatuur op klantlocaties. Deze applicatie omvat een frontend (HTML, CSS en Javascript), welke communiceert met een webservice via een JSON API.`,
			skills: ["HTML / CSS / JS", "Azure DevOps"],
			image: post,
		},
	];
	return (
		<section className="projects">
			<SectionHeader title="Projecten" />

			<div className="projects-wrapper">
				{projects.map((project, i) => {
					return <ProjectRow key={i} project={project} />;
				})}
			</div>

			<p className="more"> En nog vele andere projecten... </p>
		</section>
	);
}
