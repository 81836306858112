import React from "react";

import github from "../../Images/github.webp";
import linkedIn from "../../Images/linkedin.webp";

export default function Socials() {
	return (
		<div className="socials">
			<figure className="linkedIn">
				<a
					href="https://www.linkedin.com/in/binkdewildt"
					target="_blank"
					rel="noreferrer">
					<img src={linkedIn} alt="LinkedIn" />
				</a>
			</figure>
			<figure className="github">
				<a
					href="https://github.com/binkdewildt"
					target="_blank"
					rel="noreferrer">
					<img src={github} alt="GitHub" />
				</a>
			</figure>
		</div>
	);
}
