import React from "react";
import SectionHeader from "../Shared/SectionHeader";
import Education from "../../Models/Education/education";
import TimelineRow from "../Shared/TimlineRow";

export default function Educations() {
	const educations: Education[] = [
		{
			period: "2020 - 2024",
			title: "HOGESCHOOL LEIDEN",
			subtitle: "BACHELOR - INFORMATICA",
			text: `Gespecialiseerd in de richting 'Interactie Technologie (IAT)'. Afgestudeerd met een 9.3 en een gemiddeld behaald resultaat van 7.9.
			Tijdens de opleiding projecten gedaan op het gebied van online escape room, een complete VR-Applicatie, een full-stack IoT systeem, een E-Learning platform voor PostNL én een project voor NTR.`,
		},
		{
			period: "2015 - 2020",
			title: "RIJNLANDS LYCEUM SASSENHEIM",
			subtitle: "HAVO - NATUUR EN TECHNIEK",
		},
	];
	return (
		<section className="educations">
			<SectionHeader title="Opleidingen" />

			<div className="timeline educations-wrapper">
				{educations.map((education, i) => {
					return <TimelineRow key={i} row={education} />;
				})}
			</div>
		</section>
	);
}
