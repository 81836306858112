import React from "react";
import SectionHeader from "../Shared/SectionHeader";
import TimelineRow from "../Shared/TimlineRow";
import ExperienceItem from "../../Models/Experience/experience";

export default function WorkingExperience() {
	const workingExperience: ExperienceItem[] = [
		{
			period: "2022 - 2024",
			title: "FLORISOFT",
			subtitle: "WEB / FULL-STACK DEVELOPER",
			text: `			Bij Florisoft begonnen met een derdejaars stage voor mijn opleiding (Informatica - Hogeschool Leiden). Na het succesvol afronden van deze stage ben ik bij Florisoft enkele dagen per week blijven werken als full-stack developer en heb ik meegewerkt aan verschillende projecten. Deze projecten waren voornamelijk op het gebied van redesign/herontwerp van webshops.
			Hiernaast heb ik ook losse taken opgepakt in de rol van een full-stack developer.

			Mijn afstudeerproject, welke ik ook bij Florisoft succesvol heb afgerond, draaide om het ontwerpen en ontwikkelen van een applicatie die direct door Florisoft in gebruik is genomen. Deze applicatie ondersteunt klanten, vooral kwekers in de sierteeltsector, bij het optimaliseren van haar bedrijfsprocessen.`,
		},
	];
	return (
		<section className="experience">
			<SectionHeader title="Werkervaring" />

			<div className="timeline experience-wrapper">
				{workingExperience.map((experience, i) => {
					return <TimelineRow key={i} row={experience} />;
				})}
			</div>
		</section>
	);
}
