import React, { useRef } from "react";

import TypeWriter from "typewriter-effect";
import Logo from "../Shared/Logo";
import Socials from "../Shared/Socials";
import SectionHeader from "../Shared/SectionHeader";
import useIsInViewport from "../../Hooks/useIsInViewPort";

export default function Home() {
	const subtitleRef = useRef(null);

	return (
		<section className="home">
			<div className="title-container">
				<Logo />

				<h1 className="title">
					Bink <br />
					de Wildt
				</h1>

				<Socials />
			</div>

			<section className="introduction">
				<SectionHeader title="Wie ben ik?" />

				<h2
					className={`subtitle ${
						!useIsInViewport(subtitleRef) ? "nowrap" : ""
					}`}
					ref={subtitleRef}
				>
					Ik ben een{" "}
					<TypeWriter
						options={{
							loop: true,
							autoStart: true,
							strings: [
								"web developer",
								"backend developer",
								"web designer",
								"full-stack developer",
							],
							delay: 100,
							deleteSpeed: 70,
						}}
					/>
				</h2>

				<p>
					Ik ben een gedreven en recent afgestudeerde HBO
					Informatica-specialist, richting 'Interactie Technologie', met een
					passie voor softwareontwikkeling in regio Leiden. Met ervaring in diverse projecten,
					zowel persoonlijk als voor verschillende opdrachtgevers, heb ik een
					brede set aan vaardigheden ontwikkeld. Ontdek hier mijn werk en mijn
					uitgevoerde projecten!
					<br />
					<br />
					Neem contact op voor samenwerkingen of een diepgaande blik op mijn
					vaardigheden en expertise in softwareontwikkeling, webdesign en meer.
				</p>
			</section>
		</section>
	);
}
