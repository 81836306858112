import React from "react";
import Logo from "../Shared/Logo";
import Socials from "../Shared/Socials";

export default function Footer() {
	return (
		<section className="footer">
			<div className="contact">
				<h3 className="title">
					Contact opnemen? <br />
					Laten we praten.
				</h3>
				<p className="text">
					Stuur een email naar{" "}
					<a href="mailto:&#105;&#110;&#102;&#111;&#64;&#98;&#105;&#110;&#107;&#100;&#101;&#119;&#105;&#108;&#100;&#116;&#46;&#99;&#111;&#109;">
						&#105;&#110;&#102;&#111;&#64;&#98;&#105;&#110;&#107;&#100;&#101;&#119;&#105;&#108;&#100;&#116;&#46;&#99;&#111;&#109;
					</a>{" "}
					om in contact te komen!
				</p>
			</div>

			<div className="logo-wrapper">
				<Logo />
				<h3>
					<strong>Bedankt voor het scrollen,</strong> dit was het voor nu.
				</h3>
			</div>

			<Socials />
		</section>
	);
}
